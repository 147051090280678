import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentRadioButton.figmaUrl.android} codeUrl={checklists.componentRadioButton.codeUrl.android} checklists={checklists.componentRadioButton.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Radio buttons allow the user to select one option from a dataset.`}</p>
    <br />
    <br />
    <undefined><img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/android_radiobutton.png",
        "align": "middle",
        "alt": "android radio button"
      }}></img>
      <br></br>
      <br></br></undefined>
    <h2>{`Variant`}</h2>
    <h3>{`Themes`}</h3>
    <p>{`There are 2 themes of the radio button. There are `}<inlineCode parentName="p">{`Primary`}</inlineCode>{` and `}<inlineCode parentName="p">{`Secondary`}</inlineCode>{`.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/radiobutton_themes_2.png",
      "align": "middle",
      "width": 250,
      "height": 500,
      "alt": "radio button themes"
    }}></img>
    <h3><strong parentName="h3">{`Base Components`}</strong></h3>
    <p>{`Single radio button used for single option value.`}</p>
    <h4>{`Primary Radio Button`}</h4>
    <p>{`Example usage :`}</p>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.radio.LgnPrimaryRadioButton
            android:id="@+id/rbprimary"
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:text="Radio Button Primary"
            android:layout_marginHorizontal="@dimen/dimen_16dp"
            android:layout_marginTop="@dimen/dimen_16dp"/>
`}</code></pre>
    <br />
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    rbprimary.setText("Primary Radio Button from kotlin")
    rbprimary.isChecked

}
...
`}</code></pre>
    <h4>{`Secondary Radio Button`}</h4>
    <p>{`Example usage :`}</p>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`        <com.telkom.legion.component.radio.LgnSecondaryRadioButton
            android:id="@+id/rbsecondary"
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:text="Radio Button Secondary"
            android:layout_marginHorizontal="@dimen/dimen_16dp"
            android:layout_marginTop="@dimen/dimen_16dp"/>
`}</code></pre>
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {

            rbsecondary.setText("Secondary Radio Button from kotlin")
            rbsecondary.disable()

}
...
`}</code></pre>
    <h3><strong parentName="h3">{`Attributes`}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set id component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Text value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isEnable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set enable or disable radio button value directly via xml`}</td>
        </tr>
      </tbody>
    </table>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/basic_radiobutton.png",
      "align": "middle",
      "width": 250,
      "height": 500,
      "alt": "basic radio button"
    }}></img>
    <h3>{`Radio Button Group`}</h3>
    <p>{`Radio button group used for show multiple option value.`}</p>
    <p>{`Example usage :`}</p>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<TextView
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:text="Radio Button Example"
            android:layout_marginHorizontal="@dimen/dimen_16dp"
            android:layout_marginTop="@dimen/dimen_16dp"
            android:textAppearance="?attr/bodyLargeMedium"/>

        <TextView
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginHorizontal="@dimen/dimen_16dp"
            android:layout_marginTop="@dimen/dimen_16dp"
            android:text="Primary Radio Button"
            android:textStyle="bold" />

        <TextView
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginHorizontal="@dimen/dimen_16dp"
            android:layout_marginTop="@dimen/dimen_16dp"
            android:text="How are you today?"
            android:textStyle="bold" />

        <com.telkom.legion.component.radio.LgnPrimaryRadioGroup
            android:id="@+id/rgSamplePrimary"
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginHorizontal="16dp"
            android:layout_marginTop="8dp"
            android:showDividers="middle">
        </com.telkom.legion.component.radio.LgnPrimaryRadioGroup>

        <com.telkom.legion.component.dividers.LgnDivider
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginTop="@dimen/dimen_16dp"
            app:dividerThickness="16dp"/>


        <TextView
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginHorizontal="@dimen/dimen_16dp"
            android:layout_marginTop="@dimen/dimen_16dp"
            android:text="Secondary Radio Button"
            android:textStyle="bold" />
        <TextView
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginHorizontal="@dimen/dimen_16dp"
            android:layout_marginTop="@dimen/dimen_16dp"
            android:text="Where do you work from?"
            android:textStyle="bold" />

        <com.telkom.legion.component.radio.LgnSecondaryRadioGroup
            android:id="@+id/rgSampleSecondary"
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginHorizontal="16dp"
            android:layout_marginTop="8dp"
            android:showDividers="middle">
        </com.telkom.legion.component.radio.LgnSecondaryRadioGroup>

        <com.telkom.legion.component.dividers.LgnDivider
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginTop="@dimen/dimen_16dp"
            app:dividerThickness="8dp"/>
`}</code></pre>
    <br />
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
...
with(binding) {
            rgSamplePrimary.addAll(
                listOf(
                    "Sick",
                    "Not Fit",
                    "Healthy"
                ),
                UiKitStyle.BaseRadioButtonPrimary,
                layoutParams = RadioGroup.LayoutParams(
                    RadioGroup.LayoutParams.MATCH_PARENT, RadioGroup.LayoutParams.WRAP_CONTENT
                )
            )
            rgSampleSecondary.addAll(
                listOf(
                    "Home",
                    "Office",
                    "WFA"
                ),
                UiKitStyle.BaseRadioButtonSecondary,
                layoutParams = RadioGroup.LayoutParams(
                    RadioGroup.LayoutParams.MATCH_PARENT, RadioGroup.LayoutParams.WRAP_CONTENT
                )
            )
}
...
...
`}</code></pre>
    <h3><strong parentName="h3">{`Attributes`}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set id component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Text value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isEnable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set enable or disable radio button value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Show Dividers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:showDividers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`showDividers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show devider between radio button value`}</td>
        </tr>
      </tbody>
    </table>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/example_radiobuttongroup.gif",
      "width": 250,
      "height": 500,
      "align": "center",
      "alt": "example radio button"
    }}></img>
    <h3>{`Radio Button Container`}</h3>
    <p>{`Radio group container contains radio button group, hits ( label), helper , and error text.`}</p>
    <p>{`Example usage :`}</p>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`    <TextView
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:text="Radio Button Container"
            android:layout_marginHorizontal="@dimen/dimen_16dp"
            android:layout_marginTop="@dimen/dimen_16dp"
            android:textAppearance="?attr/bodyLargeMedium"/>

        <com.telkom.legion.component.radio.LgnPrimaryRadioContainer
            android:id="@+id/rcPrimary"
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            app:helperText="Helper : Radio Button Container Horizontal"
            android:orientation="vertical"
            android:isScrollContainer="true"
            android:layout_marginTop="@dimen/dimen_16dp"
            android:hint="Primary Radio Button Container" />

        <com.telkom.legion.component.dividers.LgnDivider
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginTop="@dimen/dimen_16dp"
            app:dividerThickness="8dp"/>

        <com.telkom.legion.component.radio.LgnPrimaryRadioContainer
            android:id="@+id/rcPrimary2"
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            app:helperText="Helper : Radio Button Container Vertical"
            android:orientation="vertical"
            android:layout_marginTop="@dimen/dimen_16dp"
            android:hint="Primary Radio Button Container" />

        <com.telkom.legion.component.dividers.LgnDivider
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:layout_marginTop="@dimen/dimen_16dp"
            app:dividerThickness="8dp"/>

        <com.telkom.legion.component.radio.LgnSecondaryRadioContainer
            android:id="@+id/rcSecondary"
            android:layout_width="match_parent"
            android:layout_height="wrap_content"
            android:orientation="horizontal"
            android:layout_marginTop="@dimen/dimen_16dp"
            android:hint="Secondary Radio Button Container" />
`}</code></pre>
    <br />
    <h4>{`Dynamic using Kotlin`}{`*`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
...
with(binding) {
            rcPrimary.addAll(
                listOf(
                    "Sample 1",
                    "Sample 2",
                    "Sample 3",
                    "Sample 4",
                ),
                "Sample 2",
                UiKitStyle.BaseRadioButtonSecondary
            )
            rcPrimary2.addAll(
                listOf(
                    "Sample 1",
                    "Sample 2"
                ),
                "Sample 1",
                UiKitStyle.BaseRadioButtonSecondary
            )

            rcSecondary.addAll(
                listOf(
                    "Dynamic Sample 1",
                    "Dynamic Sample 2"
                ),
                "Dynamic Sample 2",
                UiKitStyle.BaseRadioButtonSecondary
            )

            rcSecondary.error = "Test Error!"
}
...
...
`}</code></pre>
    <h3><strong parentName="h3">{`Attributes`}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Helper Text in Container`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:helperText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helperText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set helper text below radio button container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set label for radio button container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Orientation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:orientation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`orientation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set orientation dataset for radio button container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Scroll Container`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:isScrollContainer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isScrollContainer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set enable or disable scroll dataset for radio button container`}</td>
        </tr>
      </tbody>
    </table>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/radiobutton_container.gif",
      "width": 250,
      "height": 500,
      "align": "center",
      "alt": "radio button container"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      